.stats-card-wrapper {
    padding: 15px 0px;
    height: auto;
}

.stats-card {
    display: flex;
    flex-direction: column;
    padding: 20px;
    border: 1px solid lightgray;
    border-radius: 5px;
    gap: 5px;
}

.divider {
    border-bottom: 1px solid lightgray;
}

@media screen and (min-width: 700px) {
    .stats-card-wrapper:not(:last-of-type) {
        padding-right: 20px;
    }
}
