.custom-date-range-form {
    width: 50%;
    margin: auto;
    margin-bottom: 10%;

    h4 {
        text-align: center;
        font-size: 19px;
        margin-bottom: 5%;
    }

    .custom-btn-div {
        
        button {
            margin-top: 5% !important;
            padding: 14px;
        }
    }
}

.analytic-div {
    width: 100%;

    p {
        width: 50%;
    }

    .custom-analytic {
        width: 70%;
    }
}

.custom-text-capitalize::first-letter, tr th::first-letter, table tr::first-letter {
	text-transform: capitalize;
}

.not-allowed {
    cursor: not-allowed;
}

.center-div {
    margin: auto;
}

.hide {
    display: none;
}

.page-404 {
    position: fixed;
    height: 100vh;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 7000;
    background-color: #FF0066;
}

.error-link {
    &:hover {
        color: black !important;
    }
}